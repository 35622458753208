/*
 * - - - - Buttons - - - -
*/

/*
 * Help Button
 * The little question mark button.
 */
.btn-help {
  @extend .btn-link;
  height: 20px;
  width: 20px;
  padding: 0;
  margin-top: -6px;

  &:hover {
    background-color: transparent !important;
  }
  &:focus,
  &:active {
    outline: 0 !important;
    i {
      color: $blue-darker;
    }
  }
}

/*
 * Btn Link
 */
.btn-link {
  text-decoration: none;
}

/*
 * AGR Buttons
 */

.agr-highlight-btn {
  color: #fff !important;
  border-color: $blue-dark !important;
  background-color: $blue !important;

  &:hover,
  &:focus {
    background-color: $blue-dark !important;
  }
}

.agr-unsaved-changes {
  color: $orange-dark !important;
  border-color: $orange !important;
  background-color: #edd7c7 !important;
  &:hover,
  &:focus {
    color: $orange-darker !important;
    border-color: $orange-dark !important;
  }
}

/**
 * Button group
 */

/*
 * Toolbar button
 */
.agr-toolbar-btn {
  // use with .btn
  padding: 3px 8px; // default: 4px 8px;
  min-width: 30px;
  height: 30px;

  &:focus {
    box-shadow: none;
    outline: 0 !important;
  }
}

.agr-toolbar-btn-group {
  @extend .btn-group;
  margin-left: 3px;

  .btn {
    @extend .agr-toolbar-btn;
    @extend .agr-toolbar-btn-default;
    min-width: 28px;
    padding: 4px 4px;
  }

  &:hover .btn {
    border: 1px solid $gray-400;
  }
}

.agr-toolbar-btn-default {
  background-color: transparent;
  border-color: transparent;
  color: $gray-700;

  &:hover {
    border-color: $gray-400;
    background-color: $gray-300;
  }

  &:focus {
    border-color: $gray-400;
  }

  &.active {
    border-color: darken($gray-400, 3%);
    background-color: darken($gray-300, 3%);
    &:hover,
    &:focus,
    &:active {
      border-color: $gray-500;
      background-color: $gray-400;
    }
  }

  &.disabled,
  &:disabled,
  &.inactive {
    background-color: transparent;
    border-color: transparent;
  }
}

/*
 * Btn Icon
 */
.btn-icon {
  // Use with .btn .btn-default
  background-color: transparent;
  border-color: transparent;
  color: $gray-800;
  padding: 3px 8px; // default: 4px 8px;
  min-width: 30px;

  .fa-caret-down {
    position: relative;
    top: -2px;
    color: $gray-500;
  }

  &:hover {
    border-color: $gray-400;
    background-color: $gray-300;
  }

  &.disabled,
  &:disabled {
    background-color: transparent;
    border-color: transparent;
    color: $gray-600;
  }

  &.active,
  &:focus {
    border-color: $gray-400;
    box-shadow: none;
    outline: 0 !important;
    &:hover {
      background-color: #d9d9d9;
    }
  }
  &:active {
    background-color: $gray-400;
    box-shadow: none;
  }

  &.inactive {
    background-color: transparent;
    border-color: transparent;
    color: $gray-600;
  }
}

.agr-main-header-btn {
  line-height: 0.8;
  color: $gray-600;
  border: 1px solid $gray-400 !important;
  padding-right: 8px;
  padding-left: 8px;
  &.active {
    background-color: #cbdcec !important;
    border-color: #96b5da !important;
    box-shadow: inset 1px 1px 1px rgba(0, 100, 255, 0.1);
  }
}

/*
 * Close button
 */
.agr-btn-close {
  @extend .btn;
  @extend .btn-link;
  color: $gray-500;
  padding: 0;
  border: 0;
  line-height: 1;
  &:hover {
    color: $gray-700;
  }
}

/*
 * Save as button
 * Add class to button element
  <button class="btn btn-default btn-icon fa-save-as-btn"
    <i class="fa fa-save"></i>
    <i class="fa fa-plus"></i>
  </button>
*/
.fa-save-as-btn {
  position: relative;
  .fa-plus {
    position: absolute;
    bottom: 9px;
    right: 4px;
    font-size: 0.6rem;
  }
}

.fa-view-settings-btn {
  position: relative;
  color: $gray-800;
  .fa-cog {
    position: absolute;
    bottom: 6px;
    right: 6px;
    font-size: 0.6rem;
    color: $gray-800;
  }
  .fa-circle {
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-size: 0.7rem;
    color: $gray-200;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  }

  &:hover .fa-circle {
    color: $gray-300;
  }
  &.active .fa-circle {
    color: $gray-400;
  }
}

.fa-bulk-update-btn {
  position: relative;
  color: $gray-800;

  &:hover {
    color: $gray-900;
    .fa-edit {
      background-color: $gray-300;
    }
  }

  .fa-edit {
    position: absolute;
    bottom: 4px;
    right: 4px;
    font-size: 0.8rem;
    color: $gray-800;
    background-color: $gray-200;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  }
}

/*
 * AGR Grid Button - The ultimate button in a grid cell.
 * Use .btn-[bootstrap class] to set color.
 */
.agr-grid-btn {
  @extend .btn;
  line-height: 1;
  border-radius: 2px;
  padding: 1px 5px;
  font-size: 13px;
  margin-right: 3px;
  vertical-align: top;
  height: 100% !important;
}
