/*
 * - - - - Components - - - -
 * AGR Components. Prefix all classes with "agr-".
*/

/*
 * - AGR MINI TOOLS
 * Used in tight spaces like sidebar headers and agr list group
 *
  <div class="agr-mini-tools">
    <div class="amt-area">
      <div class="amt-title">Title</div>
      <div class="amt-sub-title">Sub-Title</div>
    </div>
    <div class="amt-area">
      <button class="amt-btn">
        <i class="fa fa-close"></i>
      </button>
    </div>
  </div>
*/
.agr-mini-tools {
  display: flex;
  flex: 1 0 auto;
  justify-content: space-between;

  .amt-area {
    display: flex;
    min-width: 0; // for the ellipsis trick
    align-items: center; // this is a life saver!
    &:last-child {
      flex-shrink: 0;
    } // so buttons won't get pushed out of parent div

    .amt-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .amt-sub-title {
      border-left: 1px solid $gray-400;
      padding-left: 6px;
      margin-left: 5px;
      color: $gray-600;
      button {
        box-shadow: none !important;
      }
    }

    .amt-divider {
      border-left: 1px solid $gray-400;
      height: 100%;
      margin: 0 0.5rem;
    }
  }

  button.amt-btn {
    @extend .btn;
    @extend .btn-link;
    color: $gray-900;
    padding: 0 3px 0;
    margin: 0;
    text-decoration: none !important;
    opacity: 0.5;
    outline: 0;
    &.amt-hidden {
      opacity: 0;
    }
    &.amt-muted {
      opacity: 0.2;
    }
    &.active {
      opacity: 1;
      &:hover {
        opacity: 1;
      }
      &.blue {
        color: $blue !important;
      } // also on hover
      &.bold {
        font-weight: bold;
      }
      &:focus {
        opacity: 1;
      }
    }
    &:hover,
    &:active,
    &:focus {
      // opacity: .7;
      // color: $gray-900;
    }
  }

  // make hidden buttons visible when hovered over the toolbar
  &:hover {
    button.amt-hidden:not(.active),
    button.amt-muted:not(.active) {
      opacity: 0.6;
      &:hover {
        opacity: 0.9;
      }
    }
  }

  .amt-space-left {
    margin-left: 4px;
  }
  .amt-space-right {
    margin-right: 4px;
  }
  .amt-grow {
    flex-grow: 1;
  }
}

/*
 * AGR Definition List
 * Used in e.g. My Profile and Orders Info

  <dl class="agr-dl">
  <dt>KEY</dt> <dd>VALUE</dd>
  </dl>
 */

.agr-dl {
  dt,
  dd {
    display: inline-block;
    line-height: 14px;
    margin-bottom: 0;
    padding-bottom: 2px;
  }
  dt {
    @extend .small;
  }
}

/*
 * AGR List
 * List used in f.ex. View settings and filters in planner.

  <ul class="agr-list">
  <li>
    <div class="agr-list-header"></div>
    <div class="agr-list-content"></div>
    <div class="agr-list-footer"></div>
  </li>
  </ul>
 */
.agr-list {
  @extend .list-group;
  margin-bottom: 0;
  margin-top: 2px;

  [cdkDrag] {
    cursor: move;
  }
  [cdkDrag]:hover {
    box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.06);
  }
}

.agr-list-container {
  background-color: #fff;
  list-style: none;
  border-left: 2px solid $gray-500;
  margin-bottom: 1px;
  box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.1);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  .agr-list-header {
    padding: 1px 8px;
  }
  .agr-list-content,
  .agr-list-footer {
    padding: 0 8px 4px;
  }

  &.cdk-drag-preview {
    box-shadow: 0px 0px 4px 0 rgba(0, 0, 0, 0.2) !important;
  }
}

/*
 * Close Tab Button
 */
.agr-close-tab {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  height: 14px;
  width: 14px;
  opacity: 0.6;
  cursor: pointer;
  &:hover {
    opacity: 1;
    background-color: $gray-700;
    color: #fff;
  }
  &:after {
    content: '×';
    display: flex;
    font-size: 13px;
    position: relative;
    top: -1px;
  }
}

/*
 * Search Tag
 * Use with classes: badge badge-xxx

<div class="search-tag badge bg-primary ms-2>
  <div>Borgartún</div>
  <div class="agr-close-tab></div>
</div>
 */
.agr-search-tag {
  display: flex;
  flex-flow: nowrap;
  align-items: center;
  height: 1.5rem;

  &.products {
    background-color: $indigo;
  }
  &.locations {
    background-color: $green;
  }
  &.responsible {
    background-color: $orange;
  }
  &.vendor {
    background-color: $purple;
  }
  &.items {
    background-color: $yellow;
  }
  &.focus {
    background-color: $gray-500;
  }
}
