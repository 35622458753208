// ag-Grid
@import '~@ag-grid-community/all-modules/dist/styles/ag-grid';
@import '~@ag-grid-community/all-modules/dist/styles/ag-theme-alpine/sass/_ag-theme-alpine-mixin';

// Variables and Bootstrap
@import './app/_styles/variables';
@import '~bootstrap/scss/bootstrap.scss';

// AGR Styles
@import './app/_styles/ag-grid';
@import './app/_styles/app-loading-indicator';
@import './app/_styles/animate';
@import './app/_styles/bootstrap-components';
@import './app/_styles/buttons';
@import './app/_styles/components';
@import './app/_styles/fonts';
@import './app/_styles/forms';
@import './app/_styles/loading-dots';
@import './app/_styles/layout';
@import './app/_styles/modals';
@import './app/_styles/navigation';
@import './app/_styles/plugins';
@import './app/_styles/toolbar';
@import './app/_styles/utils-colors';
@import './app/_styles/utils';
@import './app/_styles/setup';

// JSON Editor
@import '~jsoneditor/dist/jsoneditor.min.css';

// Font Awesome
@import '~font-awesome/css/font-awesome.css';

// NG-Select
@import '~@ng-select/ng-select/themes/default.theme.css';

// Toaster
@import '~angular2-toaster/toaster';
