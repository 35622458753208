/*
 * - - - - Forms - - - -
*/

/*
* Form Validation
* Extend Angular form validation classes with Bootstrap.
* With this rule we don't have to add [class.is-invalid] to inputs
* The .submitted class needs to be added manually on <form>
*/
.submitted .ng-invalid {
  @extend .is-invalid;
}

/*
* Ng-Select
* Needs to be styled explicitly to match Bootstrap styles
* https://github.com/ng-select/ng-select#custom-styles
*/
.submitted ng-select.ng-invalid {
  .ng-select-container {
    border-color: $red;
    &:focus-within {
      border-color: $red;
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    }
  }
  & + .invalid-feedback {
    display: block;
  }
}

/*
 * Input/select and button wrapper.
 * Place button inline with select or input element.
 */
.agr-with-button {
  display: flex;
  justify-content: space-between;

  /*.ui-select-container {
    width: 100% !important;
  }*/

  .btn {
    border: 0;
    margin-left: 4px;
  }

  .btn-help {
    margin-top: 3px;
  }
}

input[type='checkbox'] {
  cursor: pointer;
}

.radio label,
.checkbox label {
  cursor: pointer;
}

/*
 * Bootstrap v5 migration
 */

.form-group {
  margin-bottom: 0.5rem;
}
