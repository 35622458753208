/*
 * - - - - Utils - - - -
 * 
 * The bread and butter of functional CSS. These are single-purpose,
 * highly reusable classes that do one thing extremely well.
 * We have utilities for things like margins, padding, text alignment,
 * display properties, positioning properties, and more. In our
 * interpretation of utilities, only properties concerning structure and
 * layout are defined. Utility classes use a u- namespace, e.g. u-inline-block.
 *
 * Before adding new utility classes check out Bootstrap utilities: https://getbootstrap.com/docs/4.1/utilities/
*/

/*
 * Cursor
 */
.u-cursor-move {
  cursor: move;
}
.u-cursor-pointer {
  cursor: pointer;
}
.u-cursor-grab {
  cursor: grab;
}

/*
 * Layout
 */
.u-vh-30 {
  max-height: 30vh;
}
.u-vh-50 {
  max-height: 50vh;
}
.u-vh-80 {
  max-height: 80vh;
}
.u-overflow {
  overflow: auto;
}
.u-overflow-x {
  overflow-x: auto;
}
.u-overflow-y {
  overflow-y: auto;
}

/*
 * Appearance
 */
.u-no-resize {
  resize: none;
}

.u-slow-appear,
.u-slow-appear-1,
.u-slow-appear-2,
.u-slow-appear-3 {
  animation-fill-mode: forwards;
}

.u-slow-appear {
  animation: slowAppear 0.5s ease;
}
.u-slow-appear-1 {
  animation: slowAppear 0.6s ease;
}
.u-slow-appear-2 {
  animation: slowAppear 0.7s ease;
}
.u-slow-appear-3 {
  animation: slowAppear 0.8s ease;
}

/*
 * Text
 */
.u-no-select {
  user-select: none;
}

/*
 * Disabler
 * Will make element lightly transparent and disable mouse events.
 */
.u-disabler {
  animation: waitAndFade 1s ease;
} // 1 second
.u-disabler-fast {
  animation: justFade 0.3s ease;
} // .3 second
.u-disabler,
.u-disabler-fast {
  animation-fill-mode: forwards;
  pointer-events: none;
  user-select: none;
}

/*
 * Collapser
 * Adds > symbol to left or right side of element. Will rotate down if "active" class added.
 */
.u-collapser-left,
.u-collapser-right {
  display: inline-block;
  &:before {
    @extend .u-collapser-template;
  }
  &.active:before {
    transform: rotate(90deg);
    color: $blue;
  }
}
.u-collapser-left {
  &:before {
    margin: 0 4px 0 2px;
  }
  &.active:before {
    @extend .u-collapser-active-template;
  }
}
.u-collapser-right {
  &:after {
    margin: 0 2px 0 6px;
  }
  &.active:after {
    @extend .u-collapser-active-template;
  }
}

.u-collapser-template {
  position: relative;
  top: -2px;
  font-family: 'FontAwesome';
  content: '\f054';
  font-size: 10px;
  display: inline-block;
  transition-duration: 0.3s;
  color: $gray-600;
}
.u-collapser-active-template {
  transform: rotate(90deg);
  color: $blue;
}

/*
 * Chevron
 * Adds chevron (>) after element. Used a lot in breadcrumbs.
 */
.u-chevron-right {
  &:before {
    font-family: 'FontAwesome';
    content: '\f054';
    position: relative;
    font-size: 12px;
    left: 1px; // looks good in minified
    color: $gray-500;
    margin: 0 7px 0 5px;
  }
}

/*
 * Handle
 * Adds dots to on to a element on the left side. Indicates the element is drag-able.
 */
.u-handle {
  position: relative;
  &:before {
    content: '. . . .';
    color: $gray-500;
    cursor: move;
    display: inline-block;
    font-size: 12px;
    height: 76%;
    letter-spacing: 0;
    line-height: 4px;
    margin-left: 2px;
    margin-top: 2px;
    overflow: hidden;
    position: absolute;
    vertical-align: middle;
    width: 6px;
  }
}
