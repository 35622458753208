/*
 * Modals
*/

// - BOOTSTRAP OVERRIDES
.modal-content {
  box-shadow: 1px 1px 24px rgba(0, 0, 0, 0.3);
}
.modal-backdrop {
  background: rgba(0, 0, 0, 0.5) !important;
}
.modal-sm {
  width: 360px;
  max-width: 360px;
} // 300px
.modal-lg {
  width: 700px !important;
} // 900px
.modal-title {
  font-weight: normal;
} // bold

.modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}

.modal-footer {
  border: 0;
  padding-top: 0;
}

.modal-content .form-group:not(.form-check) > label {
  font-weight: bold;
}

.modal-xl .modal-lg {
  max-width: 90% !important;
  width: 900px !important;
}
