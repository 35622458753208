/*
* - - - - Bootstrap Components - - - -
* Enhance and override bootstrap component styles
*/

/*
* - Badges
*/
.badge {
  font-size: 100%;
  .fa-close {
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
}

/*
 * Drop-downs
 */
.agr-small-dropdown-btn {
  // use with bootstrap dropdown
  @extend .btn;
  @extend .btn-link;
  color: $gray-600;
  padding: 0;
  border: none;
  vertical-align: inherit;
  &:hover,
  &:active,
  &:focus {
    text-decoration: none !important;
    color: $gray-800;
  }
}

/*
 * Switches
 */
.form-switch.form-switch-big {
  .form-check-input {
    height: 25px;
    width: 50px;
  }
  .form-check-label {
    line-height: 2rem;
    margin: 0 5px;
  }
}

/*
 * Colors fixes
 *
 * In Bootstrap v5 the font color is determined by the btn background color,
 * sometimes resulting in unwanted font color.
 */

.btn-primary,
.btn-success {
  color: #fff !important;
  &:hover,
  &:active,
  &:focus {
    color: #fff !important;
  }
}
.btn-primary.active {
  background-color: $blue-darker;
}
.bg-light {
  color: $gray-900 !important;
  &:hover,
  &:active,
  &:focus {
    color: $gray-900 !important;
  }
}

/*
 * Card
 */
.card .btn-close {
  display: flex;
  margin-left: auto;
}

/*
 * Datepicker 
 * To make sure datepicker popup is above modal
 */
.ngb-dp-body {
  z-index: 1055 !important;
}
