/*
 * - - - - Fonts - - - -
*/

.small {
  color: $gray-600;
  font-size: 12px;
}

.thin {
  font-weight: 300; // used for h1 in setup pages
}

/*
 * Display string as link.
 */
.agr-link {
  color: $link-color;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
