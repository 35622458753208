/*
 * - - - - Plugins - - - -
 *
 * 3rd party plugins (node modules)
*/

/*
 * JSON Editor
 */
div.jsoneditor {
  height: 70vh;
}
a.jsoneditor-poweredBy {
  display: none;
}
.agr-json-editor-warning {
  .jsoneditor {
    border-color: $yellow;
    .jsoneditor-menu {
      background-color: $yellow;
      border-bottom-color: $yellow;
    }
  }
}
// Hide buttons
.jsoneditor-sort,
.jsoneditor-transform,
.jsoneditor-repair {
  display: none;
}

/*
 * NG Progress bar
 */
.ng-progress-bar {
  position: relative !important;
  z-index: 100 !important;
}
.ng-meteor {
  display: none !important;
} // hide box shadow at the end of progress bar
.ng-bar {
  background-color: $yellow !important;
}

/*
 * NG-Select
 */
.ng-select-hide-values {
  .ng-value {
    display: none !important;
  }
}

.ng-select.ng-select-sm {
  .ng-select-container {
    height: 25px !important;
    min-height: 25px;
    line-height: 1;
  }
}

.ng-select-options-sm {
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    padding-top: 3px;
    padding-bottom: 3px;
  }
}

.ng-select-attention .ng-select-container {
  border-color: $orange !important;
  &:hover,
  &:focus {
    color: $orange-darker !important;
    border-color: $orange-dark !important;
  }
}

// fix: dropdown panel appearing behind modal backdrop
.ng-dropdown-panel {
  z-index: 9999999 !important;
}

/*
 * CDK Drag Drop
  .cdk-drop-list	Corresponds to the cdkDropList container.
  .cdk-drag	Corresponds to a cdkDrag instance.
  .cdk-drag-preview	This is the element that will be rendered next to the user's cursor as they're dragging an item in a sortable list. By default the element looks exactly like the element that is being dragged.
  .cdk-drag-placeholder	This is element that will be shown instead of the real element as it's being dragged inside a cdkDropList. By default this will look exactly like the element that is being sorted.
  .cdk-drop-list-dragging	A class that is added to cdkDropList while the user is dragging an item.
  .cdk-drop-list-receiving	A class that is added to cdkDropList when it can receive an item that is being dragged inside a connected drop list.
 */
.cdk-drag-placeholder {
  opacity: 0;
}
.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
/* Animate an item that has been dropped. */
.cdk-drag-animating {
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}

/*
 * NGB Tooltip
*/
button.dropdown-toggle:hover[aria-expanded='true'] + .tooltip {
  display: none; // not displayed when dropdown menu is expanded
}

/*
 * MWL Resize Handle
 */
[agrResizable] {
  position: relative;
}
[mwlResizeHandle] {
  position: absolute;
  border: 1px solid $gray-400;
  z-index: 999;
}

.resize-handle-left,
.resize-handle-right {
  height: 24px;
  width: 3px;
  top: 50%;
  border-width: 0 1px 0 1px;
}

.resize-handle-top,
.resize-handle-bottom {
  height: 3px;
  width: 24px;
  left: 50%;
  border-width: 1px 0 1px 0;
}

.resize-handle-left {
  left: 1px;
}
.resize-handle-right {
  right: 1px;
}
.resize-handle-top {
  top: 1px;
}
.resize-handle-bottom {
  bottom: 1px;
}

/*
 * Popover
 */
// fix: conflicting CSS between angular2-toaster and Bootstrap v5 toasters
.toast {
  @extend .show;
}

.popover {
  font-size: $font-size-base;
}

.popover.popover-large {
  font-size: 16px;
  max-width: 450px;
}
