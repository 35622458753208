/*
 * App Loading Indicator
 * These styles should match the styles in portalclient
 * 
 * HTML in index.html
 */
#app-loading {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 99999;

  .app-loading-logo {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 1em;
    flex: 0 0 auto;
    height: 32%;

    img {
      height: 84px;
    }
  }
}
