/*
 * AGR Navbar
 */

@import '../_styles/variables';
@import '../_styles/utils-colors';

.agr-nav {
  background-color: $blue;
  border-color: $blue-dark;
  border-style: solid;
  border-width: 0 0 1px;
  display: flex;
  height: 38px;
  justify-content: space-between;
  padding-right: 10px;
  user-select: none;
}

#primary-nav,
#secondary-nav {
  display: flex;
}

#secondary-nav .agr-nav-btn {
  border-left: 1px solid $blue-dark;
}
#primary-nav .agr-nav-btn {
  border-right: 1px solid $blue-dark;
}

/*
 * Nav buttons
 */
.agr-nav-btn {
  text-decoration: none !important;
  padding: 7px 12px;
  border-width: 0;
  min-width: 39px;
  max-height: 37px;
  line-height: 1.7;
  color: #fff !important;
  text-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
  outline: none !important;
}

/*
 * Logo
 */
#nav-logo {
  &:focus,
  &:hover,
  img {
    outline: none !important;
  }
  img {
    height: 30px;
    margin-top: -3px;
  }
  strong {
    position: relative;
  }
}

#nav-logo-one-module {
  @extend #nav-logo;
}

/*
 * Nav Colors
 * Using mixins. Doc: https://sass-lang.com/guide
 */
@mixin navItem($color, $color-dark) {
  background-color: $color !important;
  border-color: $color-dark !important;
  &.active,
  &.open,
  &:hover:not(.agr-nav):not(.disabled),
  &[aria-expanded='true'] {
    background-color: $color-dark !important;
  }
}

.nav-blue,
.nav-blue .agr-nav-btn {
  @include navItem($blue, $blue-dark);
}
.nav-indigo,
.nav-indigo .agr-nav-btn {
  @include navItem($indigo, $indigo-dark);
}
.nav-purple,
.nav-purple .agr-nav-btn {
  @include navItem($purple, $purple-dark);
}
.nav-pink,
.nav-pink .agr-nav-btn {
  @include navItem($pink, $pink-dark);
}
.nav-red,
.nav-red .agr-nav-btn {
  @include navItem($red, $red-dark);
}
.nav-orange,
.nav-orange .agr-nav-btn {
  @include navItem($orange, $orange-dark);
}
.nav-yellow,
.nav-yellow .agr-nav-btn {
  @include navItem($yellow, $yellow-dark);
}
.nav-green,
.nav-green .agr-nav-btn {
  @include navItem($green, $green-dark);
}
.nav-teal,
.nav-teal .agr-nav-btn {
  @include navItem($teal, $teal-dark);
}
.nav-cyan,
.nav-cyan .agr-nav-btn {
  @include navItem($cyan, $cyan-dark);
}
.nav-gray,
.nav-gray .agr-nav-btn {
  @include navItem($gray-600, $gray-700);
}
// specially added colors for white-labeling clients only
.nav-amicis,
.nav-amicis .agr-nav-btn {
  @include navItem($amicis, $amicis-dark);
}
