/*
 * AG Grid
 */

// AG-Grid Variable overrides.
// Leave default value in comment after change.
// Doc: https://www.ag-grid.com/javascript-grid-styling/#customizing-sass-variables
$border-color: rgba(0, 0, 0, 0.08); // $flat-silver !default;

// AGR Variables.
$bg-row-odd: rgba(0, 0, 0, 0.05); // rgb(252, 253, 254) !default;
$bg-row-selected: rgba(183, 228, 255, 0.9);
$bg-row-focus: rgba(183, 228, 255, 0.45);
$cell-range-selected: rgba(0, 0, 0, 0.06);
$data-cell-height: 21px;
$list-cell-height: 30px;
$border-focus: $blue;
$editable: rgba(100, 230, 50, 0.16);
$bg-pinned-header-cells: rgba(0, 0, 0, 0.03);
$border-pinned: rgba(0, 0, 0, 0.2);
$border-row-last: rgba(0, 0, 0, 0.14);
$bg-row-today: rgba(0, 0, 0, 0.13);

// Workspace Variables
$border-period-now: 1px dashed $gray-600;
$bg-period-past: rgba(255, 255, 0, 0.08);
$border-row-group: rgba(0, 0, 0, 0.2);
$bg-total: rgba(0, 0, 0, 0.1);
$bg-total-editable: #cee2cb;
$border-total: $border-pinned;

////////////////////////////////////////

// Resolving AG-Grid issue with missing mouse events in the last row
// Link to issue: https://github.com/ag-grid/ag-grid/issues/5534
.ag-body-horizontal-scroll-viewport[style*='height: 0'] {
  display: none;
}

////////////////////////////////////////

/*
 * AG Theme alpine
 */
.ag-theme-alpine {
  // Doc: https://www.ag-grid.com/documentation/angular/themes-customising/
  @include ag-theme-alpine(
    (
      font-size: 14px,
      row-height: $list-cell-height,
      row-hover-color: null,
      odd-row-background-color: $bg-row-odd,
      range-selection-background-color: $cell-range-selected,
      selected-tab-underline-color: $blue,
      header-column-resize-handle-height: 100%,
      header-height: 38px
    )
  );

  .ag-root-wrapper {
    border-width: 0 !important;
  }
  .ag-side-bar {
    border-right: 0 !important;
  } // remove double border
  // Font size
  font-size: 14px !important;
  .ag-header-cell-text {
    font-size: 14px !important;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.64);
  }

  // Header Cells
  .ag-header {
    height: 30px;
    min-height: 30px;
  }

  .ag-header-cell-text {
    text-overflow: clip !important;
    overflow: visible !important;
    white-space: normal !important;
    height: auto !important;
    padding: 10px 0 !important;
  }

  // Rows
  .ag-row {
    // Animation: Makes grid rendering a bit prettier. Especially in Orders with focus filter.
    animation: slowAppear 0.3s ease !important;
    animation-fill-mode: forwards !important;
    &.ag-row-last {
      border-bottom-width: 0 !important; // cuts off bottom of text
      box-shadow: 0 1px 0 $border-row-last;
    }
  }
  .ag-row-focus {
    background-color: $bg-row-focus !important;
  }
  .ag-row-selected {
    background-color: $bg-row-selected !important;
    border-color: darken($bg-row-selected, 12%) !important;
    &.ag-row-focus {
      filter: brightness(93%) !important;
    }
  }

  // Cells
  .ag-cell {
    border: 1px solid transparent !important; // stop jumping values/disappearing btns (due to ellipsis) when navigating through grid.
    line-height: $list-cell-height !important;
  }
  // Fix for Chrome in Windows
  .ag-selection-checkbox {
    line-height: 1 !important;
    text-indent: 0px;
  }

  // Links
  .ag-cell a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  // Checkboxes
  .ag-selection-checkbox {
    line-height: 1 !important; // Fix for Chrome in Windows
  }

  // Menu
  // After making borders darker the separators in menu are too ugly for eyes.
  .ag-menu-separator {
    height: 1px !important;
    background-color: $gray-300 !important;
  }

  // Side-buttons
  .ag-side-buttons {
    width: 28px !important;
  }

  // Pivot mode
  .ag-pivot-mode-panel {
    display: none !important;
  } // Hide pivot checkbox. Easier than recreating the whole sidebar component.

  // Column drop bar
  .ag-column-drop-horizontal {
    height: 30px !important;
    .ag-column-drop-cell {
      min-height: 25px !important;
    }
  }

  // Status bar
  .ag-status-bar {
    padding: 2px 10px !important;
    height: 29px !important;
  }
  .ag-status-name-value {
    padding-top: 2px !important;
  }

  // Pinned
  .ag-pinned-left-header,
  .ag-pinned-right-header {
    .ag-header-cell {
      background-color: $bg-pinned-header-cells;
    }
  }
  .ag-ltr .ag-cell:not(.ag-cell-focus).ag-cell-last-left-pinned {
    border-right-color: $border-pinned !important;
  }
  .ag-floating-top {
    border-color: $border-pinned !important;
  }

  // Locked
  .locked {
    pointer-events: none;
    @extend .u-disabler-fast;
    &.editable:after {
      font-family: FontAwesome;
      content: '\f023';
      position: relative;
      float: right;
      color: $orange;
      animation: flicker 1.5s steps(24, start) infinite;
    }
    &.editable.align-right:after,
    &.editable.text-end:after {
      float: left;
    }
  }

  // Vertical text
  .vertical-text {
    height: 100px;
  }
  .vertical-text-span {
    margin: auto;
    padding-left: 15px;
    transform: rotate(90deg);
  }
}

// Cell Change Flash
// Must have specific selector (see "body") to be able to override default.
body .ag-theme-alpine .ag-cell-data-changed {
  background-color: rgba(0, 164, 183, 0.25) !important; // cyan
}

//////////////////////////////////////

/**
 * List grid.
 * E.g. Reports List grid, Workspaces list grid
 */
.agr-list-grid {
  @include ag-theme-alpine(
    (
      font-size: 14px,
      row-height: $list-cell-height,
      row-hover-color: null,
      odd-row-background-color: $bg-row-odd,
      range-selection-background-color: $cell-range-selected,
      selected-tab-underline-color: $blue,
      header-column-resize-handle-height: 100%,
      header-height: 38px
    )
  );
}

/////////////////

/*
 * Data Grid.
 * E.g. Orders grid, Report Details grid.
 */
.agr-data-grid {
  @include ag-theme-alpine(
    (
      row-height: $data-cell-height,
      odd-row-background-color: $bg-row-odd,
      row-hover-color: null,
      range-selection-background-color: $cell-range-selected,
      selected-tab-underline-color: $blue,
      header-column-resize-handle-height: 100%,
      header-height: 38px
    )
  );

  // Row and Cell colors
  .ag-row.ag-row-level-0.ag-row-group {
    background-color: unset;
  }
  .ag-row.ag-row-child {
    text-indent: 6px;
  }

  // Rows & Cells
  .ag-row-group {
    font-style: italic !important;
    .ag-cell.ag-cell-number,
    .ag-cell.ag-cell-integer,
    .ag-cell.ag-cell-currency {
      font-style: normal;
      font-weight: bold !important;
    }
  }
  .ag-cell {
    line-height: 1.3 !important;
    border-right: 1px solid $border-color !important;

    &.ag-cell-inline-editing {
      height: 20px !important;
      border-radius: 0 !important;
      box-shadow: 0 0 0 1px $blue !important;
      .ag-cell-edit-input {
        margin-top: 1px !important;
        border: none !important;
        outline: none !important;
        padding: 0 11px !important;
      }
    }
  }
  .ag-cell-not-inline-editing {
    padding: 0 6px;
  } // 0 12px
  .ag-cell-focus {
    border: 1px solid $border-focus !important;
  }

  .ag-header-cell {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  // Editable
  .editable {
    background-color: $editable;
    &.text-end input {
      text-align: right;
    }
    &.text-center input {
      text-align: center;
    }
  }
  .edited:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 2px;
    height: $data-cell-height;
    background-color: rgb(126, 185, 1);
  }
  .deleted:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 2px;
    height: $data-cell-height;
    background-color: #dc3545;
  }

  // Alignments
  .align-right,
  .align-right input {
    text-align: right;
  }
  .align-center,
  .align-center input {
    text-align: center;
  }

  // Today - Used in Item Card
  .today {
    background-color: $bg-row-today;
  }

  // Keep the looks of the floating filter for discoverability of setFilter (e.g. order exceptions)
  .ag-floating-filter-input input[class^='ag-'][type='text']:disabled {
    background-color: #fff !important;
    border-color: #babfc7 !important;
  }
}

//////////////////////////////////////

/*
 * Data Series Grid.
 * Note: The id is required as some selectors won't work without it.
 */
#workspace-grid.data-series-grid {
  // Row background and borders
  .ag-row-odd {
    background-color: inherit;
  } // remove original color
  .ag-row-group-odd {
    background-color: $bg-row-odd;
  }
  .ag-row:last-child {
    border-bottom-color: $border-row-group;
  }
  .ag-row.first-line {
    border-top-color: $border-row-group;
  } // Add border after last lines
  .ag-center-cols-container {
    border-right: 1px solid $border-color;
  }

  // Hide redundant data in grid
  .ag-row:not(.first-line):not(.row-inserted) .column-ds-col {
    text-indent: -9999px;
    text-align: unset; // text align disables the text-indent style.
  }

  // Period past/now
  .ag-cell {
    &.period-past {
      box-shadow: inset 0px 0px 5px 200px $bg-period-past;
    }
    &.period-now {
      border-right: $border-period-now !important;
      border-left: $border-period-now !important;
    }
  }

  // Totals
  .total-row .ag-cell:not(.ag-cell-range-selected),
  .total-cell:not(.ag-cell-range-selected) {
    background-color: $bg-total;
    &.editable {
      background-color: $bg-total-editable;
    }
  }
  .total-row .ag-cell,
  .total-cell {
    font-weight: bold;
    color: #000;
  }

  .total-cell {
    border-right: 1px solid $border-total;
  }

  // Config > Insert
  .row-inserted {
    border-top: 1px solid $green !important;
    border-bottom: 1px solid $green !important;
  }
  .row-inserted-submitting {
    @extend .u-disabler-fast;
  }
  .cell-inserted-editable {
    background-color: $editable;
  }
  .cell-inserted-required {
    background-color: $editable;
    &:before {
      content: '*';
      font-size: 20px;
      float: right;
      color: $gray-600;
      position: relative;
      top: 2px;
    }
    &.ag-cell-focus:before {
      display: none;
    }
  }
}

//////////////////////////////////

/*
 * Highlight when grid filters are set
 */
.ag-header-cell-filtered .ag-icon-filter {
  color: $orange;
}

.ag-status-panel-filtered-row-count .ag-status-name-value-value {
  color: #fff !important;
  background-color: $orange;
  padding: 0px 5px;
  border-radius: 3px;
  height: 20px;
}

/*
 * No data template
 */
.agr-no-data {
  img {
    width: 74px;
    filter: grayscale(100%);
    opacity: 0.5;
    margin-bottom: 10px;
  }
  span {
    color: $gray-600;
  }
}

// No row groups
.ag-no-row-groups {
  .ag-root {
    border-top-width: 0 !important;
  }
  .ag-side-bar {
    border-top-width: 0 !important;
  }
}

// NG-Select
.ag-theme-alpine.ag-popup {
  .ag-popup-editor {
    border-radius: 0 !important;
  }
}

// Using component style sheet did not work
agr-workspace-lookup-cell-editor {
  .ng-select .ng-select-container {
    border: 0 !important;
  }
}

///////////////////////////////////

/**
 * Simple grid.
 * E.g. grids in setup
 */

.agr-simple-grid {
  @include ag-theme-alpine(
    (
      font-size: 14px,
      row-height: 28px,
      odd-row-background-color: null,
      row-hover-color: null,
      selected-row-background-color: null,
      range-selection-background-color: null,
      selected-tab-underline-color: $blue,
      header-column-resize-handle-height: 100%,
      header-height: 38px
    )
  );

  .ag-cell {
    line-height: 1.3 !important; // 2.5
    &.disabled {
      color: $gray-400;
    }
  }
  .ag-row {
    border-color: transparent;
    &.ag-row-last {
      box-shadow: none;
    }
    &.ag-row-selected:not(.ag-row-focus) {
      background-color: $white !important;
      border: none !important;
    }
  }
  .ag-status-bar {
    height: 0px !important;
    padding: 0px !important;
    border: 0px;
  }
  .ag-header-viewport {
    background-color: $white;
  }
  .ag-header {
    border: none;
  }
  .ag-header-row:not(:first-child) .ag-header-cell {
    border: none;
  }
  .ag-header-cell-resize:after {
    background-color: transparent;
  }
  .ag-menu-header {
    background-color: $white;
  }
  .ag-menu-body {
    background-color: $white;
  }
  .ag-header-select-all.ag-checkbox {
    margin-right: 12px;
  }

  // Editable
  .editable {
    background-color: $editable;
    &.text-end input {
      text-align: right;
    }
    &.text-center input {
      text-align: center;
    }
  }
}
