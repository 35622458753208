/*
 * - - - - Layout - - - -
*/

$navbar-default-bg: $gray-200;
$navbar-default-border: $gray-400;

$main-header-height: 30px;

body {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  background-color: #fff;
}

/*
 *  - AGR LAYOUT
 *
 * Only styles that control the resizeable layout
 * See appearance styles below
*/
.agr-layout,
.agr-layout-section,
.agr-layout-header,
.agr-layout-body,
.agr-layout-main,
.agr-layout-main-header,
.agr-layout-sidebar {
  display: flex;
  position: relative;
}

.agr-layout {
  // first child of .agr-layout is autogenerated from route
  flex: 1 1 auto;
  flex-flow: column;
  height: calc(100vh - 38px); // make space for app nav

  .agr-layout-section {
    flex-flow: column;
    flex: 1 1 0;
    min-height: 180px;

    &:not(:first-child) {
      flex: 0 1 auto;
    } // angular-resize sets flex-basis to specific value

    .agr-layout-header {
      flex: 0 0 auto;
    }

    .agr-layout-body {
      flex-flow: row;
      flex: 1 1 auto; // grow, shrink, basis

      .agr-layout-main {
        flex: 1 1 0;
        flex-flow: column;

        .agr-layout-main-header {
          flex: 0 0 $main-header-height;
          min-height: $main-header-height;

          & + .agr-layout-main-content {
            height: calc(100% - #{$main-header-height});
          }
        }

        .agr-layout-main-content {
          display: flex;
          flex: 1 1 auto;
          overflow: hidden;
          // Make element relative because child elements that need to be 100% height (like sync btn bg)
          // will look at next parent element that has position relative to get its height.
          position: relative;
        }
      }

      .agr-layout-sidebar {
        flex: 0 0 auto;
        width: 240px;
        background-color: $navbar-default-bg;

        .agr-layout-sidebar-header {
          flex: 0 0 $main-header-height;
          padding: 2px 10px;
          min-height: $main-header-height;
        }

        .agr-layout-sidebar-content {
          padding: 10px;
          overflow-x: hidden;
          height: calc(100% - #{$main-header-height});
        }
      }

      // use instead of .agr-layout-main when there is no grid
      .agr-layout-page {
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 10px 5px 0;
        min-height: 1px;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }

  /*
   * Add when contents of sidebar is a component add this class to the component.
   * Add as a resizable fix, it just works!
   */
  .agr-layout-component {
    display: flex;
    flex-flow: column;
    position: absolute;
    height: 100%;
    width: 100%;
  }
}

// - AGR-LAYOUT Appearance

.agr-layout {
  .agr-layout-section {
    background-color: #fff;

    &:first-child {
      .agr-layout-header {
        border-top: 1px solid transparent;
      }
    }

    .agr-layout-header {
    }

    .agr-layout-body {
      .agr-layout-main {
        background-color: #fff;

        .agr-layout-main-header {
          background-color: $gray-300;
          border-bottom: 1px solid $gray-400;
        }

        .agr-layout-main-content {
        }
      }

      .agr-layout-sidebar {
        flex-flow: column; // Added so resizable sidebar displays properly, agr-layout-resizable is not used
        &.agr-layout-sidebar-left {
          border-right: 1px solid $navbar-default-border;
        }
        &.agr-layout-sidebar-right {
          border-left: 1px solid $navbar-default-border;
        }

        .agr-layout-sidebar-header {
          background-color: $gray-300;
          border-bottom: 1px solid $gray-400;
        }

        .agr-layout-sidebar-content {
          &.blacklight-party {
            background-color: #dbe2e9;
            box-shadow: inset 0 1px 10px rgba(41, 90, 180, 0.3);
          }
        }
      }
    }
  }
}
