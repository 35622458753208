/*
 * Loading Dots
 *
 * Loading indicator template:
<div class="loading-dots colors big">
  <div class="dots">
    <div class="bounce bounce1"></div>
    <div class="bounce bounce2"></div>
    <div class="bounce bounce3"></div>
  </div>
</div>
*/

.loading-dots {
  @extend .u-slow-appear;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 6px;

  .dots > .bounce {
    width: 18px;
    height: 18px;
    background-color: #333;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  &.gray .dots > .bounce {
    background-color: $gray-500;
  }
  &.colors {
    .bounce1 {
      background-color: $red;
    }
    .bounce2 {
      background-color: $yellow;
    }
    .bounce3 {
      background-color: $blue;
    }
  }
  &.yellow {
    .bounce1 {
      background-color: lighten($yellow, 10%);
    }
    .bounce2 {
      background-color: $yellow;
    }
    .bounce3 {
      background-color: darken($yellow, 6%);
    }
  }

  &.small .dots > .bounce {
    width: 12px;
    height: 12px;
  }
  &.big .dots > .bounce {
    width: 22px;
    height: 22px;
  }

  .dots > .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .dots > .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  &.fill {
    background-color: #fff;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 999;
  }
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
