/*
 * Toolbar
*/

// - TOOLBAR
.agr-toolbar {
  background-color: $gray-200;
  border-color: $gray-400;
  border-style: solid;
  border-width: 1px 0;
  display: flex;
  position: relative;
  min-height: 38px;
  padding-bottom: 1px;
  color: $gray-800;

  .navbar-divider {
    margin: 0 1px 0 3px !important;
    display: inline-block;

    &:after {
      content: '';
      position: absolute;
      top: -1px;
      height: 102%;
      width: 1px;
      background-color: #d7d7d7;
    }
  }

  a {
    color: $gray-800;
    text-decoration: none;
    &:hover {
      color: $gray-900;
    }
  }

  .agr-breadcrumbs {
    display: flex;
    align-items: center;
    margin-left: 12px;
    white-space: nowrap;
    a:hover {
      text-decoration: underline;
    }
  }
  .agr-tools {
    display: flex;
    flex-shrink: 0;
    margin-top: 3px;
    margin-right: 15px;
    margin-left: auto; // right aligns flex elements

    > agr-toolbar-btn,
    > .btn,
    > .navbar-divider,
    > .dropdown,
    > .dropdown-menu {
      margin: 0 0 0 3px; // fix margin collapse in minified code
    }
  }

  .fa-caret-down {
    font-size: 10px;
  }

  .navbar-divider {
    margin: 0 1px 0 3px !important;
    display: inline-block;

    &:after {
      content: '';
      position: absolute;
      top: -1px;
      height: 102%;
      width: 1px;
      background-color: #d7d7d7;
    }
  }

  .dropdown-toggle {
    &:hover {
      border-color: $gray-400;
      background-color: $gray-300;
    }

    &:focus {
      border-color: $gray-400;
    }
  }
}

/*
 * Tabs

<ul class="agr-toolbar-tabs">
.btn-close {
  position: relative;
  top: 1px;
  right: -1px;
}

// - TABS
/* template:
  <ul class="agr-toolbar-tabs">
    <li class="nav-item"><a routerLink="/user-management/users">Users</a></li>
    <li class="nav-item"><a class="active">Roles</a></li>
  </ul>
*/

.agr-toolbar-tabs {
  @extend .nav;
  @extend .nav-tabs;
  @extend .card-header-tabs;
  padding-top: 0.2rem;
  margin: 0 1rem;

  > li {
    @extend .nav-item;

    > a {
      @extend .nav-link;
      color: $gray-700;
      padding: 0.3rem 1rem 0.4rem;
      &:hover {
        background-color: $gray-300;
        color: $gray-900;
      }
      &.card-active {
        border-color: $gray-400 $gray-400 transparent !important;
      }
    }
  }
}

// other
.history,
.forecast {
  display: inline;
  padding: 0;
  text-align: right;
  margin: 0;
}
