/*
 * - - - - ERP Setup - - - -
 */

/*
 * Layout
 */

.setup-layout-wrapper {
  display: flex;
  flex: 1 1 auto;
  flex-flow: row;
  height: 100vh;
  width: 100vw;
  background-color: $gray-100;
  .setup-layout-sidebar {
    /* see below */
  }
  .setup-layout-main {
    /* see below */
  }
}

.setup-layout-sidebar {
  display: flex;
  flex: 0 0 300px;
  background-color: $blue;
  box-sizing: border-box;
  border: 1px solid $blue-dark;
}

.setup-layout-main {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column;
  position: relative; // for top-left and top-right elements

  .setup-layout-top-left,
  .setup-layout-top-right {
    display: block;
    position: absolute;
    width: 200px;
    height: 50px;
    top: 0;
  }
  .setup-layout-top-left {
    left: 0;
  }
  .setup-layout-top-right {
    right: 0;
  }

  .setup-layout-page {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    padding: 5px; // fix shadow cutoff

    .setup-layout-page-header {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 25px;
      margin-bottom: 20px;
    }

    .setup-layout-page-body {
      display: flex;
      flex-direction: column;
      align-items: center;

      .setup-block {
        display: flex;
        flex-flow: row wrap;
      }

      .setup-block-row {
        flex-direction: column;
        padding: 10px 30px;
      }

      .setup-settings-list {
        flex: 1 0 auto;
        margin-top: -6px;
        margin-bottom: -6px;

        .setup-settings-item {
          margin: 6px 0;
          > div,
          > input,
          > span,
          > button,
          > i {
            display: inline-block;
            vertical-align: middle;
          }
          button.btn-help {
            margin-right: 5px;
          }
        }
      }

      .setup-block-item {
        margin-bottom: 20px;
        line-height: 24px;
      }
    }

    .setup-layout-page-footer {
      height: 61px;
      margin-bottom: 40px;
    }
  }
}

.setup-layout-wrapper {
  /*
  * HTML Elements
  */

  p,
  div {
    font-size: 16px;
  }
  .small {
    font-size: 14px;
  }

  h1 {
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
  }

  h2 {
    font-size: 18px;
    color: $gray-600;
  }

  h3 {
    font-size: 16px;
    font-weight: bold;
  }

  input[type='checkbox']:not(.form-check-input) {
    transform: scale(1.3);
    padding: 10px;
    margin: 0 4px 0 2px;
  }

  /*
  * Components
  */

  .setup-block {
    padding: 14px 24px 12px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    background-color: #fff;
    margin-bottom: 20px;
  }

  .setup-count {
    font-size: 20px;
    margin-top: -6px;
    margin-bottom: 16px;
    .setup-count-title {
    }
    .setup-count-value {
      display: inline-block;
      color: $gray-600;
      width: 70px;
    }
  }

  .setup-nav-btn {
    @extend .btn;
    padding: 10px 20px;
    background-color: $pink;
    color: #fff;
    font-size: 20px;
    border-radius: 5px;
    margin: 0 14px;

    &:hover {
      color: $gray-100;
      background-color: $pink-dark;
    }

    &.setup-nav-btn-back .fa {
      margin-right: 8px;
    }
    &.setup-nav-btn-next .fa {
      margin-left: 8px;
    }

    .fa {
      font-size: 18px;
    }
  }

  .setup-progress {
    height: 26px;
    border-radius: 6px;
  }

  #setup-error {
    display: flex;
    max-width: 700px;
    i.fa {
      font-size: 38px;
      margin-right: 12px;
      align-self: center;
    }
  }

  agr-toolbar-btn {
    button {
      font-size: 16px !important;
      margin-left: 6px;
      width: 35px;
      text-align: center;
      line-height: 1.6;
      height: 35px;
    }
  }

  /*
  * Animations
  */

  .setup-layout-header {
    animation: slowAppear 0.5s ease;
  }
  .setup-block:first-child {
    animation: slowAppear 0.6s ease;
  }
  .setup-block:nth-child(2) {
    animation: slowAppear 0.7s ease;
  }
  .setup-layout-page-footer,
  .setup-count {
    animation: slowAppear 0.8s ease;
  }

  /*
   * Loading Spinner
   */

  .loading-spinner {
    display: inline-block;
    width: 22px;
    height: 22px;
    margin-bottom: 16px;
  }
  .loading-spinner:after {
    content: ' ';
    display: block;
    width: 22px;
    height: 22px;
    margin: 8px;
    border-radius: 50%;
    border: 4px solid $blue;
    border-color: $blue transparent $blue transparent;
    animation: loading-spinner 1.2s linear infinite;
  }
  @keyframes loading-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
